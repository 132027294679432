<template>
  <nav class="navbar">
    <a href="#" class="sidebar-toggler" @click.prevent="$emit('toggle-sidebar')" v-if="routeMeta.layout !== 'topOnly'">
      <FeatherIcon type="menu" />
    </a>
    <div class="navbar-content align-items-center">
      <p class="h5 font-weight-bold" v-if="routeMeta.layout !== 'topOnly'">
        {{ title }}
      </p>
      <a href="#" class="sidebar-brand" v-else>
        <img src="@/assets/images/wappin-logo.png" height="30" />
      </a>
      <ul class="navbar-nav">
        <li class="nav-item" v-if="routeMeta.layout !== 'topOnly'">
          <a class="nav-link font-weight-bold" href="#" @click.prevent="selectWorkspace()">
            {{ activeWorkspace.name }}
          </a>
        </li>
        <li class="nav-item dropdown nav-profile" v-if="routeMeta.layout !== 'topOnly'">
          <a class="nav-link dropdown-toggle"
            href="#"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            @click.prevent="topupHistories()"
            aria-expanded="false">
            (Rp. {{ workspaceBalance }})
          </a>
          <div class="dropdown-menu" aria-labelledby="profileDropdown">
            <div class="dropdown-body mt-2">
              <ul class="profile-nav p-0">
                <li class="nav-item mb-2">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Balance</small>
                    <el-tag size="mini" type="success">Rp. {{ workspaceBalance }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.free_credit">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free Credit</small>
                    <el-tag size="mini" type="success">Rp. {{ workspaceFreeQuota.free_credit.toLocaleString('id-ID') || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_bi">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp BI Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_bi || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_ui">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp UI Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_ui || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_mkt">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Marketing Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_mkt || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_auth">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Authentication Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_auth || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_util">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Utility Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_util || 0 }}</el-tag>
                  </a>
                </li>
                <li class="nav-item mb-2" v-if="workspaceFreeQuota.whatsapp_svc">
                  <a href="javascript:;" class="nav-link d-flex justify-content-between w-100">
                    <small>Free WhatsApp Service Quota</small>
                    <el-tag size="mini" type="success">{{ workspaceFreeQuota.whatsapp_svc || 0 }}</el-tag>
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown-header align-items-center">
              <a class="mb-3 w-100 btn btn-primary" @click="handlerBtnTopup">
                <font-awesome icon="money-bill-wave"/> Topup
              </a><br/>
              <a class="mb-3 w-100 btn btn-secondary" @click="$router.push({ name: 'DepositHistories' })">
                <font-awesome icon="history"/> Deposit Histories
              </a>
            </div>
            <div class="mt-2"><strong>Last Topup Histories</strong></div>
            <div class="dropdown-body mt-2" v-loading="loading.balance_histories">
              <template v-if="topup_histories_list.length > 0">
                <ul class="profile-nav p-0">
                  <li class="nav-item mb-2" v-for="item in topup_histories_list" :key="item.id">
                    <a href="javascript:;" class="nav-link d-flex justify-content-between w-100" @click="handlerBtnTopupDetail(item)">
                      <el-tag size="mini" :type="item.tag_type">{{ item.topup_amount_str }}</el-tag>
                      <small>{{ item.topup_date }}</small>
                    </a>
                  </li>
                </ul>
                <div align="center">
                  <el-button @click="$router.push({ name: 'Topup Histories', params: { workspace_id: activeWorkspace._id }})" type="text" size="small">Show all histories</el-button>
                </div>
              </template>
              <div v-else>
                <el-empty :image="lostSign" description="No topup histories."></el-empty>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown nav-profile">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <img :src="userAvatar" alt="profile">
          </a>
          <el-badge
            v-if="isAgent"
            class="nav-link dropdown-toggle"
            id="profileDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            is-dot :type="agent_availibility_color"></el-badge>
          <div class="dropdown-menu" aria-labelledby="profileDropdown">
            <div class="dropdown-header d-flex flex-column align-items-center">
              <div class="figure mb-3">
                <img :src="userAvatar" alt="">
              </div>
              <div class="info text-center">
                <p class="name font-weight-bold mb-0">{{ userProfile.name }}</p>
                <p class="text-muted mb-3">{{ userProfile.email }}</p>
              </div>
            </div>
            <div class="dropdown-body">
              <ul class="profile-nav p-0 pt-3">
                <!-- <li class="nav-item">
                  <a href="pages/general/profile.html" class="nav-link">
                    <FeatherIcon type="user" />
                    <span>Profile</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link">
                    <FeatherIcon type="edit" />
                    <span>Edit Profile</span>
                  </a>
                </li> -->
                <li class="nav-item" v-if="isAgent">
                  <a href="javascript:;" class="nav-link" @click="handlerBtnSetAvailibility">
                    <FeatherIcon type="power" />
                    Set Availibility
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click="showModal = true;">
                    <FeatherIcon type="lock" />
                    <span>Change password</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click="doShowModalCompanyProfile">
                    <font-awesome :icon="['far', 'building']"/>
                    <span>Company Profile</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="javascript:;" class="nav-link" @click.prevent="doLogout()">
                    <FeatherIcon type="log-out" />
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <b-modal v-model="showModal" title="Change Password" hide-footer>
      <b-form @submit.prevent="changePassword" @reset="closeModal">
        <b-form-group id="ig-oldpassword" label="Old password:" label-for="i-passwordl">
          <b-form-input id="i-oldpassword" type="password" placeholder="Input your old password" required v-model="v$.form.old_password.$model" />
          <b-form-invalid-feedback :state="!v$.form.old_password.$invalid" v-if="v$.form.old_password.$errors[0]">
            {{ v$.form.old_password.$errors[0].$message }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-newpassword" label="New password:" label-for="i-passwordl">
          <b-form-input id="i-newpassword" type="password" placeholder="Input your new password" required v-model="v$.form.new_password.$model" />
          <b-form-invalid-feedback :state="!v$.form.new_password.$invalid" v-if="v$.form.new_password.$errors[0]">
            {{ v$.form.new_password.$errors[0].$message }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-confirmpassword" label="Re-type new password:" label-for="i-passwordl">
          <b-form-input id="i-confirmpassword" type="password" placeholder="Re-type your new password" required v-model="v$.form.confirm_password.$model" />
          <b-form-invalid-feedback :state="!v$.form.confirm_password.$invalid" v-if="v$.form.confirm_password.$errors[0]">
            {{ v$.form.confirm_password.$errors[0].$message }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <el-button native-type="submit" :loading="loading.change_password" size="small" class="ml-2 btn-primary font-weight-bold">{{ $t('general.submit') }}</el-button>
          <el-button native-type="reset" size="small" class="btn-secondary font-weight-bold" >{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalCompanyProfile" title="Company Profile" hide-footer>
      <b-form @submit.prevent="updateCompanyProfile" @reset="closeModalCompanyProfile">
        <b-form-group label="Company Name">
          <b-form-input
            v-model="v$.company_form.name.$model"
            placeholder="Your company name">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.company_form.name.$invalid" v-if="v$.company_form.name.$errors[0]">
            {{ $t('validation.' + v$.company_form.name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Company Brand">
          <b-form-input
            v-model="v$.company_form.brand_name.$model"
            placeholder="Your company brand">
          </b-form-input>
          <b-form-invalid-feedback :state="!v$.company_form.brand_name.$invalid" v-if="v$.company_form.brand_name.$errors[0]">
            {{ $t('validation.' + v$.company_form.brand_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Company Address">
          <b-form-textarea
            v-model="v$.company_form.address.$model"
            rows="3"
            placeholder="Your company address">
          </b-form-textarea>
          <b-form-invalid-feedback :state="!v$.company_form.address.$invalid" v-if="v$.company_form.address.$errors[0]">
            {{ $t('validation.' + v$.company_form.address.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Business Entities">
          <el-select class="w-100" v-model="v$.company_form.business_entity_id.$model" placeholder="Select your business entities">
            <el-option v-for="item in business_entities" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
          <b-form-invalid-feedback :state="!v$.company_form.business_entity_id.$invalid" v-if="v$.company_form.business_entity_id.$errors[0]">
            {{ $t('validation.' + v$.company_form.business_entity_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Business Type">
          <el-select filterable class="w-100" v-model="v$.company_form.business_type_id.$model" placeholder="Select your business type">
            <el-option v-for="item in business_types" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
          <b-form-invalid-feedback :state="!v$.company_form.business_type_id.$invalid" v-if="v$.company_form.business_type_id.$errors[0]">
            {{ $t('validation.' + v$.company_form.business_type_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <el-button native-type="submit" :loading="loading.update_compro" size="small" class="ml-2 btn-primary font-weight-bold">{{ $t('general.submit') }}</el-button>
          <el-button native-type="reset" size="small" class="btn-secondary font-weight-bold" >{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal size="lg" v-model="showModalTopup" title="Balance Topup" hide-footer @hide="onHideTopup">
      <el-steps :active="current_step" finish-status="success" simple="">
        <el-step title="Fill Data" icon="el-icon-warning-outline"></el-step>
        <el-step title="Confirmation" icon="el-icon-s-claim"></el-step>
        <el-step title="Payment Detail" icon="el-icon-postcard"></el-step>
      </el-steps>
      <template v-if="current_step == 0">
        <b-form>
          <b-form-group label="Topup Amount">
            <el-radio-group @change="handlerChangeAmountBtn" v-model="form1.topup_amount" class="w-100">
              <el-radio-button v-for="(item, index) in amount_opt" :key="index" :value="item.value" :label="item.text"></el-radio-button>
            </el-radio-group>
            <el-input placeholder="Please input topup amount" v-model="form1.amount" class="mt-2">
              <template slot="prepend">Rp</template>
            </el-input>
            <b-form-invalid-feedback :state="!v$.form1.amount.$invalid" v-if="v$.form1.amount.$error">
            {{ v$.form1.amount.$errors[0].$message }}
          </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Payment Method">
            <el-select v-model="form1.payment_method_id" placeholder="Please choose payment method" class="w-100">
              <el-option v-for="item in sof" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form1.payment_method_id.$invalid" v-if="v$.form1.payment_method_id.$error">
            {{ $t('validation.' + v$.form1.payment_method_id.$errors[0].$validator) }}</b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right mt-2">
          <el-button @click="handlerBtnNextTopup" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
          <el-button @click="showModalTopup = false" size="small" class="mr-2">Cancel</el-button>
        </div>
        </b-form>
      </template>
      <template v-if="current_step == 1">
        <el-descriptions class="mt-2" title="Topup Information" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Topup Amount
            </template>
            {{ confirmation_detail.topup_amount }}
          </el-descriptions-item>
          <el-descriptions-item v-if="confirmation_detail.ppn_amount">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              VAT 11%
            </template>
            {{ confirmation_detail.ppn_amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>
              Payment Method
            </template>
            {{ confirmation_detail.payment_method }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Admin Fee
            </template>
            {{ confirmation_detail.admin_fee }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-bill-wave"/>
              Total Payment
            </template>
            {{ confirmation_detail.total_transfer }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="d-flex flex-row float-left mt-2">
          <el-button @click="current_step -= 1" type="info" size="small" class="mr-2">Back</el-button>
        </div>
        <div class="d-flex flex-row float-right mt-2">
          <el-button @click="handlerBtnNextTopup" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
          <el-button @click="showModalTopup = false" size="small" class="mr-2">Cancel</el-button>
        </div>
      </template>
      <template v-if="current_step == 2">
        <p class="mt-2 mb-2">Please transfer to this following detail. You should pay before <strong class="text-red">{{ resp.expired_at_str }}</strong></p>
        <el-descriptions class="margin-top" title="" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <!-- <font-awesome icon="money-bill-wave"/> -->
              <i class="el-icon-document"></i>
              Invoice Number
            </template>
            {{ resp.invoice_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Topup Amount
            </template>
            {{ confirmation_detail.topup_amount }}
          </el-descriptions-item>
          <el-descriptions-item v-if="confirmation_detail.ppn_amount">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              VAT 11%
            </template>
            {{ confirmation_detail.ppn_amount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Admin Fee
            </template>
            {{ confirmation_detail.admin_fee }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-bill-wave"/>
              Total Payment
            </template>
            {{ confirmation_detail.total_transfer }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>
              Payment Method
            </template>
            {{ confirmation_detail.payment_method }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Virtual Account Number
            </template>
            {{ resp.virtual_account_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="calendar"/>
              Expired Date
            </template>
            {{ resp.expired_at_str }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="mt-3 mb-3"><strong>How to Topup</strong></div>
        <el-collapse v-model="active_collapse_howtopay" accordion>
          <el-collapse-item v-for="(item, index) in resp.how_to_pay" :key="index" :title="item.channel" :name="index">
            <ol>
              <li v-for="(step, i) in item.step" :key="i">{{ step }}</li>
            </ol>
          </el-collapse-item>
        </el-collapse>
        <div class="d-flex flex-row float-right mt-2">
          <el-button v-if="!is_waiting_payment" @click="handlerBtnNextTopup" class="mr-2 ml-2" size="small" type="primary">Done</el-button>
          <el-button v-else @click="handlerBtnCancelRequest" class="mr-2 ml-2" size="small" type="primary">Cancel Request</el-button>
          <el-button @click="showModalTopup = false" size="small" class="mr-2">Close</el-button>
        </div>
      </template>
    </b-modal>
    <b-modal size="lg" v-model="showModalTopupDetail" title="Topup Request" hide-footer @hide="onHideTopup">
      <el-descriptions class="margin-top" title="" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <!-- <font-awesome icon="money-bill-wave"/> -->
              <i class="el-icon-document"></i>
              Invoice Number
            </template>
            {{ topup_history_detail.invoice_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-money"></i>
              Topup Amount
            </template>
            {{ topup_history_detail.topup_amount_str }}
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.ppn_amount">
            <template slot="label">
              <i class="el-icon-tickets"></i>
              VAT 11%
            </template>
            {{ topup_history_detail.ppn_amount_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="user"/>
              Admin Fee
            </template>
            {{ topup_history_detail.admin_fee_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-bill-wave"/>
              Total Payment
            </template>
            {{ topup_history_detail.total_transaction_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>
              Payment Method
            </template>
            {{ topup_history_detail.payment_method_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="barcode"/>
              Virtual Account Number
            </template>
            {{ topup_history_detail.virtual_account_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <font-awesome icon="money-check"/>
              Payment Status
            </template>
            <el-tag size="small" :type="topup_history_detail.tag_type">{{ topup_history_detail.status_str }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.creator">
            <template slot="label">
              <!-- <font-awesome icon="money-check"/> -->
              <i class="el-icon-s-custom"></i>
              Request By
            </template>
            {{ topup_history_detail.creator.name }}
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.status !== 'paid'">
            <template slot="label">
              <font-awesome icon="calendar"/>
              Expired Date
            </template>
            {{ topup_history_detail.expired_at_str }}
          </el-descriptions-item>
          <el-descriptions-item v-if="topup_history_detail.status === 'paid'">
            <template slot="label">
              <font-awesome icon="calendar"/>
              Paid Date
            </template>
            {{ topup_history_detail.paid_at_str }}
          </el-descriptions-item>
      </el-descriptions>
      <div class="flex justify-between-content mt-2">
        <a :href="topup_history_detail.topup_receipt_url" class="text-normal" target="_blank">
          <font-awesome icon="file-pdf"/>&nbsp;Download Invoice
        </a>
        <el-button @click="showModalTopupDetail = false" size="small" class="float-right">Close</el-button>
      </div>
    </b-modal>
  </nav>
</template>

<script>
import lostSign from '@/assets/images/undraw/lost-sign.svg';
import moment from 'moment';
import { clone, isEmpty } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, numeric, minValue, sameAs, helpers, minLength,
} from '@vuelidate/validators';

import avatar from '../../library/avatar';
import authApi from '../../api/auth';
import profileApi from '../../api/profile';
import workspaceApi from '../../api/workspaces';
import popupErrorMessages from '../../library/popup-error-messages';
import topupApi from '../../api/topup';
import agents from '../../api/agents';
import passwordComplexity from '../../library/password-complexity';

export default {
  name: 'Navbar',
  setup() {
    return { v$: useVuelidate() };
  },
  metaInfo() {
    return {
      titleTemplate: (s) => {
        this.title = s;
        return (s ? `${s} | Wappin` : 'Wappin');
      },
    };
  },
  data() {
    return {
      lostSign,
      title: '',
      showModal: false,
      showModalCompanyProfile: false,
      form: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      loading: {
        change_password: false,
        update_compro: false,
        balance_histories: false,
      },
      company_form: {
        name: '',
        brand_name: '',
        address: '',
        business_type_id: '',
        business_entity_id: '',
      },
      active_collapse_howtopay: '',
      business_entities: [],
      business_types: [],
      companyProfile: {},
      loader: null,
      loaderStack: 0,
      showModalTopup: false,
      showModalTopupDetail: false,
      current_step: 0,
      form1: {
        topup_amount: '',
        amount: null,
        payment_method_id: null,
      },
      confirmation_detail: {},
      amount_opt: [
        {
          value: 50000,
          text: 'Rp 50.000',
        },
        {
          value: 100000,
          text: 'Rp 100.000',
        },
        {
          value: 200000,
          text: 'Rp 200.000',
        },
        {
          value: 500000,
          text: 'Rp 500.000',
        },
      ],
      sof: [],
      resp: {
        how_to_pay: [],
      },
      topup_histories_list: [],
      is_waiting_payment: false,
      topup_history_detail: {},
      calculate_topup_result: {},
      agent_availibility_color: 'danger',
      is_agent_availibility: false,
    };
  },
  validations() {
    return {
      company_form: {
        name: { required },
        brand_name: { required },
        address: { required },
        business_type_id: { required },
        business_entity_id: { required },
      },
      form1: {
        amount: {
          required,
          numeric,
          minValue: minValue(10000),
        },
        payment_method_id: { required },
      },
      form: {
        old_password: {
          required,
        },
        new_password: {
          required,
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        confirm_password: {
          required,
          sameAs: sameAs(this.form.new_password, 'new password'),
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
      },
    };
  },
  mounted() {
    this.checkWorkspaceBalance();
    this.agent_availibility_color = this.userProfile.agents[0].is_available ? 'success' : 'danger';
    this.checkAvailibility();
  },
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace || {};
    },
    userAvatar() {
      return avatar(this.userProfile.name, this.userProfile.email);
    },
    routeMeta() {
      return this.$route.meta;
    },
    workspaceBalance() {
      return this.$store.state.workspace.workspaceBalance?.toLocaleString('id-ID') || 0;
    },
    workspaceFreeQuota() {
      return this.$store.state.workspace.workspaceFreeQuota;
    },
    isAgent() {
      return !isEmpty(this.userProfile.agents[0]);
    },
  },
  methods: {
    handlerBtnSetAvailibility() {
      const message = `Set your availibility status to ${this.is_agent_availibility ? 'offline' : 'online'}?`;
      this.$confirm(message, this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await agents.setMyAgentStatus({
              status: this.is_agent_availibility ? 'off' : 'on',
            })
              .catch(() => {});
            await popupErrorMessages(response);
            this.is_agent_availibility = !this.is_agent_availibility;
            this.agent_availibility_color = this.is_agent_availibility ? 'success' : 'danger';
            this.$message({
              message: 'Your availibility status has been changed',
              type: 'success',
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async checkAvailibility() {
      const response = await agents.myAgentStatus();
      if (!response.error) {
        this.agent_availibility_color = isEmpty(response.data.agent_availibility.find((v) => v.is_available)) ? 'danger' : 'success';
        this.is_agent_availibility = !isEmpty(response.data.agent_availibility.find((v) => v.is_available));
      }
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getBusinessEntities() {
      const response = await profileApi.businessEntities();
      this.business_entities = response.data.rows;
    },
    async getBusinessTypes() {
      const response = await profileApi.businessTypes();
      this.business_types = response.data.rows;
    },
    async changePassword() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading.change_password = true;
      const response = await authApi.change_password({
        old_password: this.form.old_password,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
      }).catch(() => {});
      this.loading.change_password = false;
      await popupErrorMessages(response);
      this.$message({
        type: this.$t('success'),
        message: this.$t('auth.success.change_password'),
      });
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
      this.form = {};
    },
    selectWorkspace() {
      this.$store.dispatch('workspace/clearActiveWorkspace')
        .then(() => {
          this.$router.push('/workspaces');
        });
    },
    async doLogout() {
      const loader = this.$loading.show();
      await authApi.logout();
      loader.hide();
      this.$store.dispatch('auth/doLogout').then(() => {
        this.$router.push('/auth/login');
      });
    },
    async doShowModalCompanyProfile() {
      this.showLoader();
      this.showModalCompanyProfile = true;
      this.v$.company_form.$touch();
      await this.loadCompanyProfile();
      this.company_form = clone(this.companyProfile);
      await this.getBusinessEntities();
      await this.getBusinessTypes();
      this.hideLoader();
    },
    async loadCompanyProfile() {
      const response = await profileApi.companyProfile();
      this.companyProfile = response.data.profile;
    },
    async updateCompanyProfile() {
      this.v$.company_form.$touch();
      if (this.v$.company_form.$error) return;
      this.loading.update_compro = true;
      const response = await profileApi.updateCompanyProfile({
        data: this.company_form,
      });
      this.loading.update_compro = false;
      await popupErrorMessages(response);
      this.$store.dispatch('auth/updateCompanyProfile', response.data.profile);
      this.$message({
        type: this.$t('success'),
        message: this.$t('general.success'),
      });
      this.showModalCompanyProfile = false;
    },
    closeModalCompanyProfile() {},
    async checkWorkspaceBalance() {
      if (this.activeWorkspace._id) {
        const response = await workspaceApi.check_balance({
          id: this.activeWorkspace._id,
        });
        await popupErrorMessages(response);
        this.$store.dispatch('workspace/setWorkspaceBalance', response.data);
      }
    },
    handlerChangeAmountBtn() {
      this.form1.amount = this.amount_opt.find((v) => v.text === this.form1.topup_amount).value;
      // console.log(value);
    },
    async handlerBtnNextTopup() {
      if (this.current_step === 0) {
        const pm = this.sof.find((v) => v.id === this.form1.payment_method_id);
        if (typeof this.form1.amount === 'string') {
          /* eslint-disable radix */
          this.form1.amount = parseInt(this.form1.amount);
        }
        await this.calculateTopup();
        this.confirmation_detail = {
          topup_amount: `Rp. ${this.calculate_topup_result.topup_amount.toLocaleString()}`,
          payment_method: pm.name,
          admin_fee: `Rp. ${this.calculate_topup_result.admin_fee.toLocaleString()}`,
          ppn_amount: this.calculate_topup_result.ppn_amount ? `Rp. ${this.calculate_topup_result.ppn_amount.toLocaleString()}` : '',
          total_transfer: `Rp. ${this.calculate_topup_result.grand_total.toLocaleString()}`,
        };
      } else if (this.current_step === 1) {
        await this.requestTopup();
      }
      if (this.current_step === 2) {
        this.showModalTopup = false;
        return;
      }
      this.current_step += 1;
    },
    async topupHistories() {
      this.loading.balance_histories = true;
      const { data } = await topupApi.topupHistories({
        id: this.activeWorkspace._id,
      });
      if (data.count) {
        this.topup_histories_list = data.rows.map((v) => {
          v.topup_date = moment(v.created_at).format('DD MMM YYYY');
          v.topup_amount_str = `Rp.${v.amount.toLocaleString()}`;
          v.tag_type = 'warning';
          if (v.status === 'paid') {
            v.tag_type = 'success';
          } else if (['expired', 'failed'].includes(v.status)) {
            v.tag_type = 'danger';
          } else if (v.status === 'cancelled') {
            v.tag_type = 'info';
          }
          return v;
        });
      }
      this.loading.balance_histories = false;
    },
    async requestTopup() {
      this.showLoader();
      try {
        const response = await topupApi.createRequest({
          id: this.activeWorkspace._id,
          data: {
            amount: this.form1.amount,
            payment_method_id: this.form1.payment_method_id,
          },
        });
        await popupErrorMessages(response);
        const { data: resp } = response;
        this.$message({
          message: this.$t('topup.success.create_request'),
          type: 'success',
        });
        this.resp = resp;
        this.resp.expired_at_str = moment(resp.expired_at).format('DD MMM YYYY, HH:mm');
      } catch (error) {
        this.current_step = 0;
        console.log(error);
      }
      this.hideLoader();
    },
    async handlerBtnTopup() {
      this.showLoader();
      try {
        const { data: resp_inquiry } = await topupApi.topupInquiryLastRequest({
          id: this.activeWorkspace._id,
        });
        console.log(resp_inquiry, isEmpty(resp_inquiry));
        if (!isEmpty(resp_inquiry)) {
          const {
            id, amount, admin_fee, total_transaction, virtual_account_number, expired_at, payment_method_detail,
            invoice_number, ppn_amount,
          } = resp_inquiry.current_request;
          this.confirmation_detail = {
            topup_amount: `Rp. ${amount.toLocaleString()}`,
            ppn_amount: ppn_amount ? `Rp. ${ppn_amount.toLocaleString()}` : '',
            payment_method: payment_method_detail.name,
            admin_fee: `Rp. ${admin_fee.toLocaleString()}`,
            total_transfer: `Rp. ${(total_transaction).toLocaleString()}`,
          };
          this.resp = {
            id,
            virtual_account_number,
            invoice_number,
            expired_at_str: moment(expired_at).format('DD MMM YYYY, HH:mm'),
            how_to_pay: payment_method_detail.how_to_pay,
          };
          this.is_waiting_payment = true;
          this.current_step = 2;
          this.hideLoader();
          this.showModalTopup = true;
          return;
        }
        this.showModalTopup = true;
        this.v$.form1.$touch();
        const { data } = await topupApi.paymentMethodList();
        this.sof = data.sof;
      } catch (error) {
        console.log(error);
        popupErrorMessages(error);
      }
      this.hideLoader();
    },
    onHideTopup() {
      this.form = {
        topup_amount: '',
        amount: null,
        payment_method_id: null,
      };
      this.confirmation_detail = {};
      this.resp = {
        how_to_pay: [],
      };
      this.current_step = 0;
      this.is_waiting_payment = false;
      this.showModalTopup = false;
    },
    handlerBtnCancelRequest() {
      this.$confirm(this.$t('topup.confirmation_cancel_request'), this.$t('general.confirmation'), {
        type: 'warning',
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            // cb();
            // this.showLoader();
            instance.confirmButtonLoading = true;
            await topupApi.cancelRequest({
              id: this.activeWorkspace._id,
              topup_id: this.resp.id,
            }).then(async (response) => {
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('topup.success.cancel_request'),
                type: 'success',
              });
              this.onHideTopup();
            });
            instance.confirmButtonLoading = false;
          }
          cb();
        },
      });
    },
    async calculateTopup() {
      const pm = this.sof.find((v) => v.id === this.form1.payment_method_id);
      const response = await topupApi.calculateTopup({
        workspace_id: this.activeWorkspace._id,
        data: {
          payment_method_id: pm.id,
          amount: this.form1.amount,
        },
      });
      this.calculate_topup_result = response.data;
    },
    async handlerBtnTopupDetail(item) {
      this.showLoader();
      this.showModalTopupDetail = true;
      await topupApi.detailRequest({
        id: this.activeWorkspace._id,
        topup_id: item.id,
      }).then(async (response) => {
        await popupErrorMessages(response);
        const { data } = response;
        data.result.topup_amount_str = `Rp.${data.result.amount.toLocaleString()}`;
        data.result.admin_fee_str = `Rp.${data.result.admin_fee.toLocaleString()}`;
        if (data.result.ppn_amount) data.result.ppn_amount_str = `Rp.${data.result.ppn_amount.toLocaleString()}`;
        data.result.total_transaction_str = `Rp.${data.result.total_transaction.toLocaleString()}`;
        data.result.payment_method_str = data.result.payment_method_detail.name;
        data.result.paid_at_str = data.result.status === 'paid' ? moment(data.result.paid_at).format('DD MMM YYYY, HH:mm') : '-';
        data.result.tag_type = 'warning';
        if (data.result.status === 'paid') {
          data.result.tag_type = 'success';
        } else if (['expired', 'failed'].includes(data.result.status)) {
          data.result.tag_type = 'danger';
        } else if (data.result.status === 'cancelled') {
          data.result.tag_type = 'info';
        }
        data.result.status_str = data.result.status.replace(/_/g, ' ').toUpperCase();
        data.result.expired_at_str = moment(data.result.expired_at).format('DD MMM YYYY, HH:mm');
        this.topup_history_detail = data.result;
      });
      this.hideLoader();
    },
  },
};
</script>
